
import { defineComponent, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";

import isEnabled from "@/composables/isEnabled.js";
import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
import { useMsal } from "@/composition-api/useMsal";

import { useCleanStore } from "@/composables/emptyStore";

export default defineComponent({
  name: "error-500",
  components: {},
  setup() {
    const store = useStore();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const isLoggingAdb2c = computed(() => store.getters.isLoggingAdb2c);

    const { redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    const signOut = async () => {
      const idTokenHint = localStorage.getItem("tokenAdb2c") as string;
      localStorage.clear();
      useCleanStore();
      await instance.logoutRedirect({
        idTokenHint: idTokenHint,
      });
    };

    return {
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,
      isEnabled,
      isAuthenticated,
      signOut,
      isLoggingAdb2c,
    };
  },
});
